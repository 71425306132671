header .logo,
header .cc-logo{
    position: relative;
    top: 0;
    height: 80px;
}
header .logo a,
header .cc-logo a{
    position: absolute;
    top: -40px;
    height: 80px;
}
header .logo a svg,
header .cc-logo a svg{
    fill: #fff;
}
.three-top-ctas-container .cta-box{
    display: inline-block;
}
.icon-cta-with-intro-container .cta-container {
    display: inline-block;
}
.icon-cta-with-intro-container .cta-box {
    flex: auto;
    display: inline-block;
}
.resources-container .resources-block .resource-item .left img {
    display: inline-block;
}
.box-cta-with-intro-container .box-cta-container{
    display: inline-block;
}
.box-cta-with-intro-container .cta-box {
    display: inline-block;
}
.icon-anchor-link-container .tab {
    display: inline-block;
}
.icon-anchor-link-container .tab li {
    flex: auto;
    display: inline-block;
}
.our-department-container .department-container {
    display: inline-block;
}
.our-department-container .department-container .department-list {
    flex: auto;
    float: left;
}
.programs-section-container .programs-container .gradient-overly {
    background: none !important;
}
